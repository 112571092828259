<template>
  <div>
    <div v-if="'hetun' === heTunInfo"><descriptionOfCooperationZj></descriptionOfCooperationZj></div>
    <div v-else>
      <div class="title">合作说明</div>
      <div style="text-align: center; line-height: 20px; margin-top: 10px;">
        发布时间：2022-01-07 17:56:14
      </div>
      <div data-v-0ab10f90="" style="font-size: 14px;">
        <p><strong>&nbsp;希望我们价值观相似、理念彼此认同</strong></p>
        <p>1、企业定位----喧哗聒噪的时代，{{ webKeyword.OPEN_COM_NAME }}立志成为一个有温度、有深度、有质感的平台。追求长远、稳健的发展。</p>
        <p>2、商品定位----市场上商品假货泛滥的时代，{{ webKeyword.OPEN_COM_NAME }}专注国内外百年品牌品质商品的发掘。品质保证、专业专注是我们的宗旨。</p>
        <p>3、价格定位----华而不实的时代，{{ webKeyword.OPEN_COM_NAME }}力求回归商业本质，尊重商业规律务实求胜。{{ webKeyword.OPEN_COM_NAME }}必须要摈弃持续亏损烧钱电商平台的供应链模式，调整价格结构，不做行业最低但也绝对不是最高。在合理的水平上盈利，才能实现长期发展，真正为伙伴负责。</p>
        <p>4、文化定位----浮躁的时代，{{ webKeyword.OPEN_COM_NAME }}希望成为受尊重的企业，我们的劳动要得到认可和尊重，我们的付出要获得应有的回报；{{ webKeyword.OPEN_COM_NAME }}不会与不正规的供应商为伍，也不愿意与只想投机，不想付出，不愿学习创新，只懂得抱怨的负能量卖家合作。我们要跟卖家与供应商一起，达到共赢格局。</p>
        <p><strong>我们能为您提供什么</strong></p>
        <p>&nbsp;</p>
        <div class="container">
          <p>&nbsp;</p>
          <p><img alt="" src="https://www.houniao.hk/upload/image/20180414/20180414152915_56574.png" width="940"></p>
          <p>招募对象：微商店家、第三方平台店家、自营电商、实体店家以及所有需要代理发货者。</p>
          <p>目标：为国内卖家提供从海外正品货源采购、仓储管理、订单处理、配货包装到全球配送的一站式服务。</p>
          <p>主营类目：母婴、保健、美妆（后期会发展多个品类）</p>
          <p><strong>招募优势</strong></p>
          <p>商品丰富：拥有六大国际仓库，囊括千家国际知名品牌，储备上万个全球优质产品</p>
          <p>零成本：无需储货，先销售再下单，完全零风险</p>
          <p>正品保障：绝对海外原装正品、海外仓库直发国内，100%正品保障</p>
          <p>订单管理：一键导入导出，批量订单处理，轻松实现管理各渠道订单数据</p>
          <p>物流配送：专业的物流配送体系，提供保税仓拣货、质检、包装、配送一站式服务</p>
          <p>售后服务：5*8小时服务，订单全程服务，咨询、赔付、物流、退货全程跟踪</p>
          <p>入驻方式：直接在我们{{ webKeyword.OPEN_COM_NAME }}分销官网注册即可成为我们的分销商。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import descriptionOfCooperationZj from '@/views/Help/components/descriptionOfCooperationZj'

/**
 * @author  XuHongli
 * @date  2022/11/15 10:49
 * @version 1.0
 * @description
 */
export default {
  name: 'DescriptionOfCooperation',
  components: { descriptionOfCooperationZj },
  computed: {
    ...mapGetters(['webKeyword']),
    heTunInfo() {
      if (process.env.NODE_ENV === 'production') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'pre') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'hetun') {
        return process.env.NODE_ENV
      } else {
        return process.env.NODE_ENV
      }
    }
  }
}
</script>

<style scoped>

</style>
