<template>
  <div>
    <div class="title">合作说明</div>
    <div style="text-align: center; line-height: 20px; margin-top: 10px;">
      发布时间：2022-04-11 20:19:26
    </div>
    <div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
      <p style="color: #ff0000;text-indent: 2em;"><span> 希望我们价值观相似、理念彼此认同</span></p>
      <p style="color: #ff0000;text-indent: 2em;"><span>1、企业定位----打破传统做小而精、专而新的供应链企业</span></p>
      <p style="text-indent: 2em;"><span><span
        style="color: #ff0000;">2、商品定位----</span>深圳市合囤供应链有限责任公司专注开拓高性价比的商品，品质保证、专业专注是我们的初心，帮助客户让每一次消费更具价值！</span>
      </p>
      <p style="color: #ff0000;text-indent: 2em;"><span>3、价格定位----所有产品均为出厂底价，一件代发自由加价，利润透明可观</span></p>
      <p style="text-indent: 2em;"><span><span style="color: #ff0000;">4、文化定位----</span>打破传统做小而精、专而新的供应链企业</span></p>
      <p><strong> <span>我们能为您提供什么</span> </strong></p>
      <p><strong> <span>招募对象：</span> </strong><span>厂家、品牌方、实力经销商等！</span></p>
      <p><strong> <span>目标：</span> </strong><span>为卖家提供更优质正品货源采购、仓储管理、订单处理、配货包装到全球配送的一站式服务。</span>
      </p>
      <p><strong> <span>主营类目</span> </strong><span>：轻奢、家电、3C数码、家纺家居、食品、日用招募优势</span>
      </p>
      <p><strong> <span>商品丰富：</span> </strong><span>拥有10万+SKU、品牌覆盖面广，涵盖全品类主流产品囊括百家知名品牌，储备上万个全球优质产品</span></p>
      <p><strong> <span>零成本：</span> </strong><span>无需储货，先销售再下单，完全零风险</span></p>
      <p><strong> <span>正品保障：</span> </strong><span>绝对原装正品、厂家直发，100%正品保障</span></p>
      <p><strong> <span>订单管理：</span> </strong><span>一键导入导出，批量订单处理，轻松实现管理各渠道订单数据</span>
      </p>
      <p><strong> <span>物流配送：</span> </strong><span>专业的物流配送体系，提供拣货、质检、包装、配送一站式服务</span>
      </p>
      <p><strong> <span>售后服务：</span> </strong><span>6*8小时服务，订单全程服务，咨询、赔付、物流、退货全程跟踪</span>
      </p>
      <p><strong> <span>入驻方式：</span> </strong><span>直接在我们深圳市合囤供应链有限责任公司分销官网注册即可成为我们的采购商。</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'descriptionOfCooperationZj'
}
</script>

<style scoped>

</style>
